
.landing-page {
  min-height: 75vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.background-img {
  background:  linear-gradient(to bottom,  #17191c3f, #17191C), url("../../assets/images/scheduling.png"); 
  background-size: cover;
  background-position: center;
  
}

.landing-page-title {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1.5rem;
}


.landing-page-description {
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.landing-page .container {
  text-align: center;
}

.container .MuiButton-root {
  margin-top: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;

  background-color: #f50057;
  color: white;
  font-weight: bold;
  
  box-shadow: 0 0 1rem #fdfdfd;
  
}


.smooth-scroll {
  scroll-behavior: smooth;
}